import React from 'react';
import { useParams,Link  } from 'react-router-dom';
import './ValidaCredencial.css';

const ValidaCredencial = () => {
  const { idCredencial } = useParams();
  
  if (!idCredencial) {
    return (
      <div className="valida-credencial">
        <h2>Error</h2>
        <p>No se ha proporcionado una credencial válida.</p>
      </div>
    );
  }

  // Usa comillas invertidas para la interpolación de variables
  const imagePath = `/credenciales/${idCredencial}.jpg`;

  return (    
    <div className="valida-credencial">
      <Link to="/">
        <img src="/logocongregacion.png" alt="Home" className="home-link-image" />
      </Link>
      <h2>Ministro de Culto de la Congregación Cristiana en Chile</h2>      
      <h3>Credencial vigente</h3>
      <img src={imagePath}   className="credencial-image" />
    </div>
  );
};

export default ValidaCredencial;
