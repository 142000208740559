import React from 'react';
import { FaYoutube } from 'react-icons/fa';
import './Home.css';

const Home = () => {
  return (
    <div className="home">       
      <img src="/logocongregacion.png" className="logo" alt="Logo Congregación" />
      <div className="info-container">
        <div className="info-text">
          <h3>CULTOS ON-LINE</h3>
          <p>Viernes 20:00 Horas.<br />Sábado 19:00 Horas.</p>
        </div>
        <div className="info-link">
          <a href="https://www.youtube.com/channel/UCpPS00retpjkLwW4sOYQPLQ" target="_blank" rel="noopener noreferrer" className="link">
            <FaYoutube size={40} color="#FF0000" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Home;
